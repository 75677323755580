@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

* {
  font-family: 'Red Hat Display', -apple-system, 'Noto Sans', 'Helvetica Neue', Helvetica,
    'Nimbus Sans L', Arial, 'Liberation Sans', 'PingFang SC', 'Hiragino Sans GB',
    'Noto Sans CJK SC', 'Source Han Sans SC', 'Source Han Sans CN',
    'Microsoft YaHei', 'Wenquanyi Micro Hei', 'WenQuanYi Zen Hei', 'ST Heiti',
    SimHei, 'WenQuanYi Zen Hei Sharp', sans-serif;
}

*, *::before, *::after {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  font-size: 18px;
  color: #1C1C1C;
  background-color: #f0f0f0;
  -webkit-font-smoothing: antialiased;
}

/* disable focus blue highlight */
input:focus, textarea:focus, button:focus {
  outline: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

@media only screen and (min-width: 600px) {
  html,
  body {
    font-size: 20px;
  }
}

/* disable chrome click feedback box */
* { 
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); 
}

/* recaptcha */
.grecaptcha-badge { 
  visibility: hidden;
}

/* react modal */
.ReactModal__Overlay {
  opacity: 0;
  -webkit-transition: opacity 0.2s ease-in-out;
  -moz-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
}

.ReactModal__Overlay--on-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.sidebar {
  margin-left: -15rem;
}

.sidebar--on-open {
  margin-left: 0;
}

.ReactModal__Overlay--before-close .sidebar {
  margin-left: -15rem;
}

.message-modal {
  transform: scale(0.8);
}

.message-modal--on-open {
  transform: scale(1);
}

.ReactModal__Overlay--before-close .message-modal {
  transform: scale(0.8);
}


/* day picker */
:root {
  --rdp-cell-size: 45px !important;
  --rdp-accent-color: #FFCC33 !important;
  --rdp-background-color: white !important;
  --rdp-outline-selected: 2px solid #FFCC33 !important;
}

.rdp {
  margin: 0 !important;
}

.rdp-caption_label {
  font-size: 1rem !important;
}

.rdp-months {
  justify-content: center;
}

.rdp-nav_button svg path {
  fill: #FFC31F;
}

.rdp-head_cell {
  color: #979797 !important;
  font-size: 20px !important;
  font-weight: 400 !important;
}

.rdp-day_today:not(.rdp-day_selected) {
  border-radius: 50% !important;
  border: 1px solid #DEDEDE !important;
  font-weight: 400 !important;
}